<template>
  <NeoModal
    :title-header="$t('certificate')"
    @close="closeModal"
    scrollable
    width="100%"
    height="100vh"
    max-width="800px"
    @clickOutsideCallback="closeModal"
  >
    <v-card class="printable">
      <v-progress-linear
        v-if="processing"
        color="primary"
        indeterminate
        rounded
        height="7"
      ></v-progress-linear>
      <v-card-actions class="actions">
        <v-btn class="buttons" outlined color="primary" @click="closeModal">{{
          $t("close")
        }}</v-btn>
        <v-btn class="buttons" color="primary" @click="download">{{
          $t("download")
        }}</v-btn>
      </v-card-actions>
      <v-card-text>
        <div ref="certificate" class="page certificate">
          <div class="inside-border">
            <div class="artwork">
              <p class="header">{{ $t("certificateOfOwnership") }}</p>
              <div class="details">
                <div class="center">
                  <img
                    class="image"
                    :src="`${imageURL}?certificate`"
                    alt="artwork"
                    crossorigin="anonymous"
                  />
                </div>
                <div class="center">
                  <p>{{ $t("author") }}: {{ info.artist }}</p>
                  <p>{{ $t("title") }}: {{ info.title }}</p>
                  <p>
                    {{ $t("year") }}:
                    {{ `${info.circa ? "Circa " : ""}${info.year}` }}
                  </p>
                  <p>
                    {{ $t("material") }}: {{ info.material[$store.state.lang] }}
                  </p>
                  <p>
                    {{ $t("size") }}: {{ `${info.height}x${info.width}cm` }}
                  </p>
                  <div class="qrcode">
                    <canvas ref="qrcode" class="qrcanvas"></canvas>
                  </div>
                </div>
              </div>
            </div>

            <div class="noa">
              <div class="details">
                <div v-if="$store.state.lang === 'ja'" class="collector-info">
                  <div  class="certificate-text-top">
                    <p>{{ $t("certifyThat") }}</p>
                    <p>{{ $t("certifyThat2") }}</p>
                  </div>
                  <p >
                    <a class="collector-name">{{ lastName }} {{ firstName }}</a><a class="collector-tag"> {{ $t("wa") }}</a>
                  </p>
                  <div class="certificate-text-bottom">
                    <p>
                      <a>{{ $t("ofTotalWork") }}</a>
                      <a class="field-underline">{{ ownership }}%</a>
                      <a>{{ $t("equating") }}</a>
                    </p>
                    <p>
                      <a class="field-underline">
                        {{ nFrames }}
                        {{ nFrames === 1 ? $t("frame") : $t("frames") }}</a
                      >{{ $t("proudOwner") }}
                    </p>      
                  </div>
                </div>
                <div v-else class="collector-info">
                  <p class="certificate-text-top">
                    {{ $t("certifyThat") }}
                  </p>
                  <p class="collector-name">
                    {{ firstName }} {{ lastName }}
                  </p>
                  <div class="certificate-text-bottom">
                    <p>
                      <a>{{ $t("proudOwner") }} </a
                      ><a class="field-underline">
                        {{ nFrames }}
                        {{ nFrames === 1 ? $t("frame") : $t("frames") }}</a
                      >
                    </p>
                    <p>
                      <a>{{ $t("equating") }}</a
                      ><a class="field-underline">{{ ownership }}%</a
                      ><a> {{ $t("ofTotalWork") }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="signatures">
              <div class="details">
                <div class="center">
                  <img
                    class="logo"
                    src="@/assets/main-logo.svg"
                    alt="NEO ONE ART"
                  />
                </div>
                <div class="center">
                  <p>{{ $t("tokyo") }}, {{ $t("japan") }}</p>
                  <p>{{ $t("date") }}: {{ certificateDate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </NeoModal>
</template>

<script>
import NeoModal from "@/components/Molecules/NeoModal";
import html2pdf from "html2pdf.js";
import QRCode from "qrcode";
import ArtworkDS from "@/services/ArtworkDS";

export default {
  name: "Certificate",
  components: {
    NeoModal,
  },
  props: {
    firstName: String,
    lastName: String,
    nFrames: Number,
    ownership: Number,
    imageURL: String,
    id: String,
    artist: String,
    title: String,
  },
  data: () => {
    return {
      certificateDate: "",
      processing: false,
      info: {
        material: { en: "", es: "", ja: "" },
        year: "",
        height: "",
        width: "",
        title: "",
        artist: "",
        circa: "",
      },
    };
  },
  methods: {
    openModal() {},
    closeModal() {
      this.$emit("close");
    },
    download() {
      this.processing = true;
      html2pdf(
        this.$refs.certificate,
        {
          margin: 1,
          filename: `certificate_${this.lastName}_${this.firstName}_${this.info.title}_${this.info.artist}.pdf`,
          image: { type: "jpeg", quality: 0.5 },
          html2canvas: {
            dpi: 300,
            letterRendering: true,
            useCORS: true,
            scale: 1,
            scrollY: 0,
            windowWidth: "1358",
            windowHeight: "1920",
            logging: false,
          },
          jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
        },
        (error) => {
          console.log(error);
        }
      ).then(() => {
        this.processing = false;
        this.closeModal();
      });
    },
  },
  async beforeMount() {
    this.info = await ArtworkDS.read(this.id);
  },
  mounted() {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    this.certificateDate = new Date().toLocaleDateString(undefined, options);
    console.log(
      `https://neoone.art/details/${this.artist}/${this.title}/${this.id}`
    );

    QRCode.toCanvas(
      this.$refs.qrcode,
      `https://neoone.art/details/${this.artist}/${this.title}/${this.id}`,
      { height: 256 },
      function (error) {
        if (error) console.error(error);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@page {
  size: A4;
  margin: 30mm 45mm 30mm 45mm;
  /* change the margins as you want them to be. */

  .certificate-text-top {
    font-size: var(--font-size-4);
    padding-left: 1cm;
    padding-right: 1cm;
  }
  .certificate-text-bottom {
    font-size: var(--font-size-4);
  }
  .collector-name {
    font-size: var(--font-size-2);
    display: inline;
  }
  .header {
    font-size: 28px;
  }
}

@media print {
  printable {
    width: 21cm;
    height: 29.7cm;
    margin: 0;
  }

  /* change the margins as you want them to be. */
}

// .printable {
//   display: block;
// overflow: auto;
// }
.field-underline {
  font-weight: 600;
  border-bottom: solid 1px #222;
}
.certificate {
  font-family: "Montserrat";
  height: auto;
  @media only screen and (min-width: 768px) {
    height: 27.7cm;
  }
}

p {
  margin-bottom: 0.5cm;
}

.qrcode {
  height: 2cm;
  width: 2cm;
  display: flex;
  margin-left: auto;
}

.qrcanvas {
  height: 100% !important;
  width: auto !important;
}

.inside-border {
  border: 2px solid #111;
  height: auto;
  @media only screen and (min-width: 768px) {
    height: 26cm;
  }
}

.certificate {
  border: 3px solid #111;
  padding: 3%;
  @media only screen and (min-width: 768px) {
    padding: 0.75cm;
  }
}

.certificate-text-top {
  font-style: italic;
  font-size: var(--font-size-5);
  padding-left: 1cm;
  padding-right: 1cm;
  @media only screen and (min-width: 768px) {
    font-size: 28px;
  }
}

.certificate-text-bottom {
  font-style: italic;
  margin-top: 1cm !important;
  margin-bottom: 2cm;
  font-size: var(--font-size-5);
  @media only screen and (min-width: 768px) {
    font-size: 28px;
  }
}

.collector-name {
  display: inline;
  font-weight: 600;
  font-size: var(--font-size-4);
  border-bottom: solid 1px #222;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
  @media only screen and (min-width: 768px) {
    font-size: 40px;
  }
}

.collector-tag {
  display: inline;
  font-weight: 300;
  font-size: var(--font-size-4);
  border-bottom: solid 1px #222;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
  @media only screen and (min-width: 768px) {
    font-size: 40px;
  }
}

.actions {
  display: flex;
}
.buttons {
  margin: auto;
}

.artwork {
  height: auto;
  @media only screen and (min-width: 768px) {
    height: 45%;
  }
}

.noa {
  height: auto;
  @media only screen and (min-width: 768px) {
    height: 40%;
  }
}

.signatures {
  height: auto;
  @media only screen and (min-width: 768px) {
    height: 15%;
  }
}

.header {
  font-size: var(--font-size-4);
  font-style: italic;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid #160303;
  padding-top: 0.5cm;
  padding-bottom: 0.5cm;
  margin-left: 1cm;
  margin-right: 1cm;
  @media only screen and (min-width: 768px) {
    font-size: 40px;
  }
}

.details {
  width: 100%;
  display: flex;
  padding-top: 0.5cm;
  flex-wrap: wrap;
  align-items: flex-start;
}
.center {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.collector-info {
  position: relative;
  margin: auto;
  text-align: center;
  padding-top: 1cm;
  border-top: solid 1px #666;
}
.image {
  width: auto;
  padding-bottom: 20px;
  @media only screen and (min-width: 768px) {
    width: 60mm;
    padding-bottom: 0px;
  }
  padding-left: 10px;
  padding-right: 10px;
  height: auto;
}
.logo {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and (min-width: 768px) {
    width: 60mm;
    margin-bottom: 0px;
    padding: 0px;
  }
}
</style>
